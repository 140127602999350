<template>
  <div id="app">
    <preloader/>
    <navbar />
    <main>
      <div>
        <transition name="fade">
        <router-view :items=allItems ></router-view>
        </transition>
      </div>
    </main>
    <foot />
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import Foot from './components/Footer'
import Preloader from './components/preloader'

export default {
  name: 'App',
  components: {
    Navbar,
    Foot,
    Preloader
  },
  data () {
    return {
      allItems: [
        {
          id: 21,
          title: "Malla ciclónica galvanizada",
          category: "Mallas",
          description: "La malla ciclónica o malla ciclón es un producto muy versátil para delimitación perimetral, fabricada con malla de alambre galvanizado por inmersión en caliente, dando una máxima durabilidad en el ambiente. Si se requiere una mayor durabilidad en el cercado es recomendable utilizar alambre galvanizado recubierto de PVC, aportando más vida al producto.",
          specs: "Alambre galvanizado por inmersión en caliente clase comercial. Longitud de rollos de 20m. Altura de rollo hasta 4m. Diferentes aberturas dependiendo de las necesidades, desde 25×25 hasta 69×69. En el alambre galvanizado recubierto PVC tiene un aditivo para soportar los rayos UV u otros agente corrosivos como la salinidad en costas.",
          aplicaciones: "Delimitación perimetral. Seguridad perimetral. Caminos rurales. Jaulas. Agrícola. Prisiones",
          advantages: "",
          image: "2.jpg",
          medidas: "malla ciclonica.jpg",
          nuevo: false,
        },
        {
          id: 28,
          title: "Rejas de acero",
          category: "Reja de acero",
          description: "Las rejas de acero es un sistema de enrejado que se caracteriza por tener un diseño con pliegues en forma de letra “V” a lo largo y ancho del panel que lo conforma.  Este es un producto de alta resistencia que brinda una gran seguridad. Las rejas tienen diferentes componentes como abrazaderas, placas barrenadas, bayoneta y paneles, todos se galvanizan y recubren con poliéster termoendurecido parara brindar mayor protección contra la oxidación.",
          specs: "Electrosoldada y con doblez. Recubrimiento galvanizado con zinc por inmersión en caliente, fosfato y pintura poliéster termoendurecida. Calibre 6. Alturas 0.63m, 1.00m, 1.50m, 2.00m, 2.50m. Ancho 2.50m",
          aplicaciones: "El uso es ideal para brindar protección a espacios abiertos, algunas de las principales aplicaciones son: Delimitación en áreas escolares. Seguridad en estacionamientos. Blindar casas. Perímetros en áreas públicas. Cercar jardines o terrenos. Restricción de espacios ",
          advantages: "",
          image: "10 (1).jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 22,
          title: "Malla hexagonal",
          category: "Mallas",
          description: "Los rollos de malla hexagonal reforzada, también conocida como malla pollera o malla avícola, son fabricados con alambre suave y maleable galvanizado por inmersión en caliente para usar en una gama muy amplia, desde la construcción hasta arreglos florales. Es conocida como una malla multipropósito justo por la variedad de aplicaciones en las que se puede utilizar. La malla está tejida helicoidalmente de tal manera que forma figuras de hexágonos que se distribuyen de forma uniforme.",
          specs: "Alambre delgado de bajo carbono para dar suavidad y galvanizado por imersión en caliente. Amarre por torsión formando un hexágono y así dar estructura a la malla. Rollos estándar de 45 metros. Alambre de refuerzo para dar estructura.",
          aplicaciones: "Jaulas para animales pequeños. Aplicación de yeso en muros (repellar). Protección de huertos. Uso para sostener aislantes. Arreglos florales. Enjarres y aislantes en construcción. Cerco complementario . Aislante en muros exteriores. Almacenes de granos",
          advantages: "La ventaja de tener una estructura hexagonal es que este diseño ofrece una mayor resistencia constructiva y los alambres sufren menos tensión al aplicarse fuerza. El alambre con el que se fabrica tiene un refuerzo cada determinada distancia, lo cual otorga mayor estructura y firmeza. Además esta malla es de muy fácil manejo.",
          image: "3.jpg",
          medidas: "malla hexagonal.jpg",
          nuevo: false,
        },
        {
          id: 13,
          title: "Concertina",
          category: "Concertina",
          description: "La concertina galvanizada o concertina inoxidable es una protección de navajas punzo cortantes en forma helicoidal fabricada con lamina de acero galvanizada por inmersión en caliente y un alma de alambre igualmente recubierto de zinc por inmersión en caliente con alto grado de dureza, troquelada y rolada para ofrecer una estructura robusta y uniforme. Para una mayor durabilidad la concertina galvanizada se puede fabricar en lamina de acero inoxidable 430 o 304 y un alma de alambre inoxidable 304 de alto grado de dureza aportando una mayor durabilidad en cualquier condición extrema.",
          specs: "Lámina y alambre galvanizado o inoxidables con un alto grado de dureza. Presentación de rollos sencillos de 8 m y 4m en el caso de que sea doble. Modelo Residencial, Industrial y Militar. Diámetro del rollo estándar de 18”, 24” y 31” Se puede fabricar especialmente en rollos de diámetros diferentes.",
          aplicaciones: "Protección y seguridad perimetral. Prisiones. Residencial. Industrial. Barrera para uso militar",
          advantages: "",
          image: "16.jpg",
          medidas: "concertina.jpg",
          nuevo: false,
        },
        {
          id: 25,
          title: "Metal desplegado",
          category: "Metal desplegado",
          description: "El Metal Desplegado o Lámina desplegada se obtiene del proceso de corte y estiramiento, obteniendo como resultado rombos uniformes a lo largo de la hoja o rollo desplegado. Al realizar este proceso sobre la lámina de metal, se le da una estructura mas robusta y visualmente se vuelve decorativo. Obtenemos falso plafón de metal desplegado a partir de lámina y se usa principalmente en la construcción para sostener el yeso o realizar una variedad de diseños.",
          specs: "Acabado en lámina negra rolada en frio, galvanizada y el plafón lleva una cubierta de pintura asfáltica. Presentación en la lamina desplegada en rollo con un ancho de 0.915 m y un largo de 11 m. El plafon es de A = 0.915 m x L = 22 m.",
          aplicaciones: "Herrería. Pisos. Filtros. Jaulas. Estructuras. Plafones",
          advantages: "",
          image: "metaldd.jpg",
          medidas: "metal desplegado.jpg",
          nuevo: false,
        },
        {
          id: 43,
          title: 'Lamina perforada',
          category: "Metal desplegado",
          description: 'La lámina perforada es una hoja metálica fabricada en diversos tipos de materiales con perforaciones distribuidas uniformemente en toda la superficie, las hojas de lamina perforada son una excelente opción para pisos y escalones, filtros, revestimientos, ventilación, acabados decorativos entre otras muchas aplicaciones.',
          specs: 'El espesor de la lámina perforada puede ir desde calibres delgados como calibre 22 (0.76mm) hasta espesores mucho más gruesos como ½ pulgada o hasta ¾ de pulgada. Tipos de acero: Acero al carbón, Acero inoxidable, Aluminio, Acero Galvanizado. Tipo de Barreno: Perforación redonda, Perforación cuadrada, Perforación oblonga, Perforación decorativa',
          image: 'laminap.jpg',
          medidas: 'perforadat.png'

        },
        {
          id: 44,
          title: 'Malla electrosoldada',
          category: "Mallas",
          description: 'La malla Electrosoldada se fabrica a través de un proceso de laminado y corrugado en frío, usando como materia prima alambrón; posteriormente se solda eléctricamente utilizando la más avanzada tecnología.',
          specs: 'La malla electrosoldada es fabricada con alambre de acero de alta resistencia, laminado en frío, corrugado o liso y soldados con soldadura por resistencia eléctrica. Los alambres empleados en la fabricación de la malla provienen de alambrón de palanquilla, laminado en caliente y posteriormente trabajado en frío, adquiriendo gran resistencia a la tensión. La malla estándar se fabrica en rollos de 2.50 m. de ancho por 40.00 m. de largo o sobre pedido en hojas de 2.50m. x 6.00 m.',
          advantages: 'En la industria de la construcción, como acero de refuerzo. Proporcionan esfuerzo estructural necesario en: losas de cimentación, entrepiso, pavimentos rígidos, Revestimiento en silos, bóvedas, túneles, canales. Muros divisorios de carga, de contención, Elementos prefabricados (tubos de concreto, vigas pretensadas), Refuerzo para mampostería.',
          image: 'electro.jpg',
          medidas: 'electrot.jpeg'

        },
        {
          id: 14,
          title: "Criba",
          category: "Mallas",
          description: "La Malla Criba o Malla de acero entretejida y galvanizada por inmersión en caliente con múltiples usos, desde la construcción hasta las artes decorativas. La criba al ser tejida permite darle mayor resistencia a la ruptura y al tener un alto grado de zinc permite una gran duración en condiciones extremas como el clima húmedo.",
          specs: "",
          aplicaciones: "Jaulas para pájaros. Arreglos florales. Cernir/cribar arena",
          advantages: "Acabado galvanizado por inmersión en caliente. Presentación en rollo de 10 y 30 m. Fabricado bajo la norma ASTM-A-740",
          image: "criba2.jpg",
          medidas: "criba.jpg",
          nuevo: false,
        },
        {
          id: 23,
          title: "Malla mosquitera",
          category: "Mallas",
          description: "La malla mosquitera o tela mosquitera se fabrica a partir de alambres delgados galvanizados y tejidos automáticamente dando gran estructura y resistencia en comparación de las plásticas que se queman fácilmente.",
          specs: "Alambres delgados galvanizados con una fuerte capa de zinc. En rollos de diferentes alturas y dos longitudes; 10m y 30m. Abertura de 18 x 14 mm. Diámetro del alambre 0.28 mm",
          aplicaciones: "Protección de puertas y ventanas. Invernaderos. Filtros. Uso domestico",
          advantages: "",
          image: "Malla-Mosquitera.jpg",
          medidas: "malla mosquitera.jpg",
          nuevo: false,
        },
        {
          id: 20,
          title: "Malla borreguera",
          category: "Mallas",
          description: "Malla ganadera especializada fabricada con alambre de acero de alta resistencia recubierto de zinc por inmersión en caliente para dar una gran durabilidad en diferentes climas. Por su diseño le permite evitar las deformaciones a lo largo de la malla borreguera y asi una larga vida de uso.",
          specs: "lambre galvanizado por inmersión en caliente clase comercial resistente a la corrosión. Nudo tipo bisagra que no lastima el ganado. Entre cada nudo de la malla existe una curva de tensión que permite dar flexibilidad al momento que el ganado ejerce presión en algún recargón y así disminuir la deformación.",
          aplicaciones: "BOVINO. CAPRINO. CINEGETICO AGRICOLA. CAPRINOS",
          advantages: "",
          image: "7.jpg",
          medidas: "malla borreguera.jpg",
          nuevo: false,
        },
        {
          id: 24,
          title: "Malla rígida ondulada",
          category: "Mallas",
          description: "Malla de alambre galvanizado recubierto de zinc entrelazada en forma cuadrada para dar estructura y aportando gran versatilidad en su uso.",
          specs: "Alambre desde calibre 6 a 15 galvanizado por inmersión en caliente. Su presentación puede ser en rollos u hojas dependiendo del requerimiento y especificación de la malla. Se puede solicitar con alambre galvanizado recubierto en PVC. Fabricación en gran cantidad de aberturas, alturas, largos y calibres adecuándonos a las necesidades del cliente.",
          aplicaciones: "Herrería. Seguridad perimetral. Criba o tamiz. Industrial. Automotriz",
          advantages: "",
          image: "5.jpg",
          medidas: "malla ondulada.jpg",
          nuevo: false,
        },
        {
          id: 26,
          title: "Multimalla",
          category: "Mallas",
          description: "Multimalla, malla soldada o Malla galvanizada es una malla de alambre galvanizado electrosoldada en los cruces dándole solides en las uniones con una gran variedad de diseños.",
          specs: "Malla Galvanizada de Alambre galvanizado por inmersion en caliente. Electrosoldado en las uniones. Rollos en diferentes aberturas y altura con una longitud de 20m",
          aplicaciones: "Jaulas. Proteccion como reja. Exhibidores. Filtros. Multiusos",
          advantages: "",
          image: "6 (2).jpg",
          medidas: "multimalla.jpg",
          nuevo: false,
        },
        {
          id: 35,
          title: 'Malla sombra',
          category: 'Mallas',
          description: 'Protección para el ganado, es una garantía de defensa al reducir el estrés calorico en el pre parto, peso del ternero y producción de leche. Protege de los rayos solares, al reducir la temperatura hasta 10°C.',
          specs: 'Instalar un sistema de sombreado con malla sombreadora para ganadería para evitar el contacto directo con los rayos solares. Procurar una adecuada cantidad de agua de buena calidad Cuidar la alimentación para que el alimento sea más fácilmente digerible. Garantizar la ventilación y la circulación del aire. Reducir la incidencia de insectos. Para esto se puede utilizar eficazmente la malla antiáfidos. Evitar la sobrepoblación',
          image: 'mallasombra.jpeg',

        },
        {
          id: 41,
          title: 'Malla triple nudo',
          category: 'Mallas',
          description: 'Es un producto que brinda muy buena estética al lugar que se va a cercar, es más fácil y rápido de instalar y se consigue un ahorro muy representativo vs la malla ciclónica.',
          advantages: 'Se consigue un ahorro de 18% vs la malla ciclónica con el sistema de retenidas. No ocupa accesorios como la malla ciclónica. Más resistente que la malla ciclónica Más rapidez de instalación',
          aplicaciones: 'Mismos usos que la malla ciclónica.',
          specs: ' 400% veces más fuerte que la malla ciclónica. Triple galvanizado. Alambres de alta resistencia. ¡Alta resistencia al corte!. Triple nudo que evita desplazar los alambres horizontales y verticales. Fácil instalación - ¡Única en México!',
          image: 'nudo.jpg',
          medidas: 'nudot.png'

        },
         {
          id: 42,
          title: 'Malla de triple torsión',
          category: 'Mallas',
          description: 'Es la alternativa más viable y económica para evitar el desprendimiento de material suelto e inestable en el la cara de taludes y terraplenes, con problemas de estabilidad; previniendo una gran cantidad de derrumbes y percances dentro de obras civiles (Autopistas, edificaciones).',
          advantages: 'Permite controlar pequeños desprendimientos. Guiado de pequeños desprendimientos al pie del talud. Rápida ejecución. Escaso impacto visual de la actuación. Solución muy económica.',
          specs: 'Malla galvanizada clase III de Triple Torsión, abertura de 5x7 y 8x10 cms. Calibre 12.5 y 10 en las aristas, todas las redes son fabricadas con alambre galvanizado.',
          image: 'triple.jpg',
          medidas: 'triplem.jpeg'

        },
        {
          id:45,
          title: 'Cerca de tubos',
          category: 'Cerca de Tubos',
          description: 'La cerca de tubos es un sistema de enrejado que se caracteriza por tener un diseño robusto y demasiado resistente. Este es un producto de alta resistencia que brinda una gran seguridad. Las cercas tubulares son de diferentes calibres, comunmente instaladas sobre una base de concreto para una rijida fijacion y brindar mayor seguridad.',
          image: 'rejadet.jpg',
          medidas: 'rejat.jpeg'
        },
        {
          id: 36,
          title: 'Cinta plastica',
          category: 'Mallas',
          description: 'Cinta plástica para cubrir cercas de malla ciclónica y otras rejas de acero, contiene un rollo de 50 metros de largo por 57 mm de ancho, colocación en lugares donde requiera total privacidad. Para cubrir mallas ciclónicas y rejas de acero.. Brinda seguridad y privacidad. Con protector contra rayos UV y antioxidantes para mayor durabilidad y resistencia. 3 Años de garantía por defectos de fabricación, satisfacción total.',
          specs: 'Ancho: 57 mm. Largo: 50 m. Peso: 1.700 kg de cinta.',
          image: 'cinta3.jpg',
          medidas: 'cintas.jpeg'
        },
        {
          id: 38,
          title: "Poste de concreto",
          category: "Tuberia para cercas",
          description: "POSTE DE CONCRETO UTILIZADO PARA CERCAR TERRENOS PREPARADO CON 5 ALAMBRES PARA 5 HILOS DE PUAS. CUENTA CON UNA RESISTENCIA DE 150 KG/CM2",
          specs: "La mejor inversión a mediano y largo plazo. Durables y resistentes. No se agrietan ni desmoronan. Se instalan cada 3 metros. 5 grapas integradas. Aumenta la plusvalía de su propiedad. Por su material hay mínimo interés al robo. La mejor opción para delimitar su terreno y mejorar su apariencia.",
          image: "postec.jpeg",
          medidas: "posteco.jpeg",
        },
        {
          id: 39,
          title: "Picos de seguridad",
          category: "Accesorios para cercas",
          description: "Pieza construida de acero en 2mm, resistente a su manipulación y daño, pintado en color negro y esmaltado al horno.",
          specs: "Color: Negro. Presentación: 1 unidad. Espesor: 2mm. Tipo: Tiburón. Modelo: Simple. Largo: 1m",
          aplicaciones: "Puntas de seguridad para ser instalados en paredes medianeras, muros, rejas, fondos de patio y jardines, restringiendo el paso no deseado. Se recomienda que sean soldadas a las rejas y en los muros, soldadas a esparragos insertados en el muro.",
          image: "puas.jpeg",
        },
        {
          id: 40,
          title: "Cercas electricas",
          category: "Mallas",
          description: "Postería de fierro galvanizado o negro en el espesor requerido por el cliente, hilos de alambre galvanizado en el diámetro especificado, alturas desde 1.00 hasta lo requerido por el cliente, energizadores de alto voltaje en diferentes marcas, sirenas, estrobos, controles remotos, etc.",
          specs: "Porqué proteger su casa, negocio, escuela, empresa, fábrica, rancho, etc.Las Cercas eléctricas o electrificadas son las barreras de protección más eficaces para usarse en casas habitación, fábricas, talleres, ranchos, etc. Constan de un cercado perimetral de varios hilos de alambre galvanizado soportado en postería de fierro normal o galvanizado mediante piezas conocidas como aisladores. Funciona con un voltaje de 8 000 a 16 000 watts y un bajísimo amperaje.",
          image: "electric.jpeg",
        },
        {
          id: 30,
          title: "Tubo Galvanizado",
          category: "Tubo galvanizado",
          description: "El tubo galvanizado es un tipo de tubo de acero recubierto con zinc, que lo protege del desarrollo de óxido de hierro. Se fabrica con lámina galvanizada por inmersión en caliente, que es rolada, soldada y, por último, la costura es recubierta de zinc mediante un proceso que garantiza los más altos estándares nacionales e internacionales.",
          specs: "Espesores desde cal 14 a cal 22. Tubo galvanizado Cédula 30 y Cédula 40. Largos estándar de 4.80m , 6.00m, 6.40m (se puede fabricar a diferentes largos). Lámina galvanizada por inmersión en G60 y G90",
          aplicaciones: "Instalación de mallas y cercados. Invernaderos. Soporte de rejas. Portones. Jaulas",
          advantages: "Larga vida útil. El tubo galvanizado tiene una vida útil más prolongada que uno sin recubrimiento, la cual puede ser mayor de acuerdo con el espesor de las capas. En un entorno urbano, estos tubos tienen una vida estimada de 20 a 40 años. Variedad de usos. Se pueden utilizar para la construcción de carreteras, puentes, muelles, o bien, en la fabricación de rejas de acero para delimitación perimetral. Estética. El acabado brillante del zinc deja un producto brillante, fácil de limpiar y visualmente agradable, que se adapta a todo tipo de decoración. Costo/beneficio. El tubo galvanizado es más económico que el de acero inoxidable y, al tener una vida útil prolongada y una gran resistencia, no necesita mantenimiento o reparación, por lo que es una inversión rentable a largo plazo. Los tubos galvanizados son muy resistentes ante la corrosión, las inclemencias del tiempo y los golpes. A diferencia del acero inoxidable, el galvanizado es más ligero, por lo que no aportan peso a las construcciones donde se utilizan.",
          image: "17.jpg",
          medidas: "tubo galvanizado.jpg",
          nuevo: false,
        },
        {
          id: 37,
          title: "Gaviones",
          description: "Las estructuras de contención de Gavión son canastas rectangulares de malla de alambre con malla metálica de triple torsión de alambre galvanizado, que se llena con piedras y tiene la función de dar estabilidad a un terreno. Llenadass de rocas en el lugar del proyecto para formar estructuras monolíticas flexibles y permeables, tales como muros de contención para proyectos comerciales, industriales y de carreteras.",
          specs: "Las estructuras de contención de Gavión son canastas rectangulares de malla de alambre con malla metálica de triple torsión de alambre galvanizado, que se llena con piedras y tiene la función de dar estabilidad a un terreno. Llenadass de rocas en el lugar del proyecto para formar estructuras monolíticas flexibles y permeables, tales como muros de contención para proyectos comerciales, industriales y de carreteras.",
          advantages: "Adaptable a diversos suelos y climas. Preservación de áreas dañadas por la erosión. Estabilidad estructural y flexible monolítica",
          aplicaciones: "Control de ríos. Protección estructural. Conservación de suelo",
          category: "Mallas",
          image: "gavion.jpeg",
          medidas: "gavT.jpeg",
          nuevo: false,
        },
        {
          id: 9,
          title: "Alambre de púas",
          category: "Alambres",
          description: "Los rollos de alambre de púas están formados por dos alambres galvanizados trenzados cal 12.5 para dar una mayor resistencia a la fuerza aplicada y tiene una púa entrelazada con 4 puntas de alambre galvanizado calibre 14.5 aportando seguridad al perímetro cercado.",
          specs: "Rollos de 34kg, 30kg y 20kg. Fabricado en alambre galvanizado por inmersión en caliente. Calibres 12.5 y calibre 14 en las púas. 4 puntas con una distancia de 4” entre púa y púas",
          aplicaciones: "Delimitación: excelente opción para cercar y brindar protección perimetral. Tales como terrenos, ranchos ganaderos, viviendas, áreas industriales, zonas de alta seguridad, centros educativos, áreas verdes, negocios, huertos, áreas deportivas o terreno urbano. Integración: es ideal para complementar cercas de mallas ciclónicas, graduadas, rejas y bardas de concreto.  Suplente: una buena solución para sustituir el alambre de púas tradicional. Manejo: de corrales perimetrales e interiores para proteger que no entren ni salgan animales como vacas, borregos, cabras, cerdos, perros, venados, aves, conejos o caballos.",
          advantages: "Elevada resistencia a la presión. Fácil integración con otros materiales. Excelente adaptabilidad a diferentes terrenos. Acero mejorado con mayor maleabilidad que otorga mayor elongación. Alambre de alta resistencia altamente confiables. Mayor resistencia a la oxidación. Púas más filosas. Mayor resistencia a la ruptura que los alambre tradicionales Mayor tiempo de vida en climas húmedos y extremos. Con grosor considerable para su fácil visualización. Ahorro en los postes para el cerco",
          image: "12.jpg",
          medidas: "alambre galvanizado.jpg",
          nuevo: false,
        },
        {
          id: 11,
          title: "Alambre galvanizado",
          category: "Alambres",
          description: "El alambre de acero galvanizado lo puede encontrar desde 1 kg para la venta al menudeo y hasta 1 ton.  En granel, los rollos de 1kg vienen en cajas de 20 rollos.",
          specs: "Alambre liso galvanizado por inmersión en caliente con acabado liso y uniforme del zinc. Suave y manipulable. Presentación de 1 kg y etiquetado individualmente en caja de 20 rollos. Arriba de 10 kg la presentación es en rollo único y puede ser a granel. Se puede fabricar recubierto en PVC en cal 10.5 y 11.5",
          aplicaciones: "Amarres de mallas. Sujeción de productos agrícolas. Amarres en construcción. Tendederos. Manualidades o artesanías",
          advantages: "Es un material altamente resistible a la corrosión. Tiene una vida útil más larga. No requiere de mantenimiento periódico. No crea residuos. Tiene una buena apariencia y permanece sin cambios en sus propiedades al estar a la intemperie. Es fácil de manipular e instalar. Es resistente a golpes o raspaduras por su aleación.",
          image: "11 (1).jpg",
          medidas: "alambre galvanizado.jpg",
          nuevo: false,
        },
        {
          id: 46,
          title: "Alambre galvanizado por tonelada",
          category: "Alambres",
          description: "El alambre de acero galvanizado lo puede encontrar desde 1 kg para la venta al menudeo y hasta 1 ton.  En granel, los rollos de 1kg vienen en cajas de 20 rollos.",
          specs: "Alambre liso galvanizado por inmersión en caliente con acabado liso y uniforme del zinc. Suave y manipulable. Presentación de 1 kg y etiquetado individualmente en caja de 20 rollos. Arriba de 10 kg la presentación es en rollo único y puede ser a granel. Se puede fabricar recubierto en PVC en cal 10.5 y 11.5",
          aplicaciones: "Amarres de mallas. Sujeción de productos agrícolas. Amarres en construcción. Tendederos. Manualidades o artesanías",
          advantages: "Es un material altamente resistible a la corrosión. Tiene una vida útil más larga. No requiere de mantenimiento periódico. No crea residuos. Tiene una buena apariencia y permanece sin cambios en sus propiedades al estar a la intemperie. Es fácil de manipular e instalar. Es resistente a golpes o raspaduras por su aleación.",
          image: "11 (1).jpg",
          medidas: "tonelada.jpeg",
          nuevo: false,
        },
        {
          id: 10,
          title: "Alambre de púas de alta resistencia",
          category: "Alambres",
          description: "Los rollos de alambre de púas están formados por dos alambres galvanizados trenzados cal 12.5 para dar una mayor resistencia a la fuerza aplicada y tiene una púa entrelazada con 4 puntas de alambre galvanizado calibre 14.5 aportando seguridad al perímetro cercado.",
          specs: "Fabricado con alambres de alta resistencia galvanizados por inmersión en caliente. Calibre 15.5 y calibre 16.5 para la púa. Distancia entre las púas de 5” Las púas tienen cuatro puntas. Rollos de 300 y 360 m. Doble capa de zinc. Rendimiento aproximado de 20 m/kg.",
          aplicaciones: "Delimitación: excelente opción para cercar y brindar protección perimetral. Tales como terrenos, ranchos ganaderos, viviendas, áreas industriales, zonas de alta seguridad, centros educativos, áreas verdes, negocios, huertos, áreas deportivas o terreno urbano. Integración: es ideal para complementar cercas de mallas ciclónicas, graduadas, rejas y bardas de concreto.  Suplente: una buena solución para sustituir el alambre de púas tradicional. Manejo: de corrales perimetrales e interiores para proteger que no entren ni salgan animales como vacas, borregos, cabras, cerdos, perros, venados, aves, conejos o caballos.",
          advantages: "Elevada resistencia a la presión. Fácil integración con otros materiales. Excelente adaptabilidad a diferentes terrenos. Acero mejorado con mayor maleabilidad que otorga mayor elongación. Alambre de alta resistencia altamente confiables. Mayor resistencia a la oxidación. Púas más filosas. Mayor resistencia a la ruptura que los alambre tradicionales Mayor tiempo de vida en climas húmedos y extremos. Con grosor considerable para su fácil visualización. Ahorro en los postes para el cerco",
          image: "12.jpg",
          medidas: "alambre galvanizado.jpg",
          nuevo: false,
        },
        {
          id: 12,
          title: "Cadena Galvanizada",
          category: "Accesorios para cercas",
          description: "Cadena de eslabón o cadena galvanizada eletrosoldada grado 30 esta formada en frio y con eslabones electrosoldados, para después ser probada en su totalidad cumpliendo los estándares ante la resistencia a la ruptura y la torsión.",
          specs: "Acabado pulido o galvanizado. Presentación en cubeta de 25 y 50 kg. Fabricado bajo la norma ASTM-A-413",
          aplicaciones: "Herreria. Seguridad. Delimitar áreas. Decorativa",
          advantages: "",
          image: "13.jpg",
          medidas: "cadena galvanizada.jpg",
          nuevo: false,
        },
        {
          id: 15,
          title: "Alambre Zig Zag",
          category: "Alambres",
          description: "",
          specs: "",
          aplicaciones: "",
          advantages: "",
          image: "19.jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 16,
          title: "Perfil sujetador",
          category: "Tuberia para cercas",
          description: "",
          specs: "",
          aplicaciones: "",
          advantages: "",
          image: "20.jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 18,
          title: "PTR Galvanizado",
          category: "Tubo galvanizado",
          description: "",
          specs: "",
          aplicaciones: "",
          advantages: "",
          image: "22.jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 19,
          title: "Lamina galvanizada",
          category: "Láminas",
          description: "",
          specs: "Mínimo espesor de corte 0.2464mm (cal 32 .0097″). Máximo espesor de corte 4.9352mm (Cal 6 .1943″). Rollo máximo de 1,400mm de ancho. Peso máximo de 20 tons. Tolerancia de corte +/- 0.005″. Venta de lámina galvanizada en rollo o en cinta",
          aplicaciones: "",
          advantages: "",
          image: "14.jpg",
          medidas: "LAMINA GALVANIZADA.jpg",
          nuevo: false,
        },
        {
          id: 27,
          title: "Poste ganadero",
          category: "Tuberia para cercas",
          description: "El poste ganadero es utilizado para dar estructura la instalación de malla borreguera o alambres de púas y esta fabricado a partir de riel de ferrocarril asegurando suficiente dureza y durabilidad.",
          specs: "Fabricado de acero de alta resistencia tipo riel de ferrocarril. Recubierto de una pintura color verde anticorrosivo para una mayor resistencia al ambiente. Tiene un ancla que evita excavación para colocarse. Dependiendo de la aplicación se puede instalar el ligero o reforzado si es para ganado de gran volumen.",
          aplicaciones: "Delimitación perimetral. Viñedos. Caminos rurales. Agrícola. Ranchos",
          advantages: "",
          image: "posteg.jpeg",
          medidas: "poste ganadero.jpg",
          nuevo: false,
        },
        {
          id: 29,
          title: "Tuberia para cercas",
          category: "Tuberia para cercas",
          description: "Tubería para cercas troqueladas útil para ahorrar tiempos en mano de obra y accesorios de cerca. También tiene una mayor durabilidad y cero mantenimiento comparado con otros sistemas que utilizan tornillos y tuercas para la sujeción de accesorios y púas. Al usar el Poste de línea integrado el instalador ahorra en tiempo un 30% y en accesorios para el cercado.",
          specs: "Lamina galvanizada por inmersión en G 60 y G 90. Largos estándar y presentación por pieza. Diámetro de 1 7/8” (48 mm)",
          aplicaciones: "Delimitación perimetral. Caminos rurales. Agrícola. Ranchos",
          advantages: "",
          image: "Tuberia para cercas.jpg",
          medidas: "tuberia para ercas.jpg",
          nuevo: false,
        },
        {
          id: 31,
          title: "Abrazadera de arranque",
          category: "Accesorios para cercas",
          description: "",
          specs: "",
          aplicaciones: "",
          advantages:"",
          image: "29.jpg",
          medidas: "ABRAZADEA DE ARRANQUE.jpg",
          nuevo: false,
        },
        {
          id: 32,
          title: "Abrazadera de broche",
          category: "Accesorios para cercas",
          image: "30.jpg",
          medidas: "ABRAZADERA DE BROCHE.jpg",
          nuevo: false,
        },
        {
          id: 33,
          title: "Abrazadera doble",
          category: "Accesorios para cercas",
          image: "31.jpg",
          medidas: "ABRAZADERA DOBLE.jpg",
          nuevo: false,
        },
        {
          id: 34,
          title: "Cople con simple",
          category: "Accesorios para cercas",
          image: "25.jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 1,
          title: "Tapón simple",
          category: "Accesorios para cercas",
          image: "23.jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 2,
          title: "Cople con oreja",
          category: "Accesorios para cercas",
          image: "24.jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 3,
          title: "Abrazadera de tension",
          category: "Accesorios para cercas",
          image: "28.jpg",
          medidas: "ABRAZADERA DE TENSION.jpg",
          nuevo: false,
        },
        {
          id: 4,
          title: "Espada integral",
          category: "Accesorios para cercas",
          image: "33.jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 5,
          title: "Solera galvanizada",
          category: "Accesorios para cercas",
          image: "Solera Galvanizada.jpg",
          medidas: "",
          nuevo: false,
        },
        {
          id: 6,
          title: "Visagra industrial",
          category: "Accesorios para cercas",
          image: "32.jpg",
          medidas: "ABRAZADERA INDUSTRIAL.jpg",
          nuevo: false,
        },
        {
          id: 7,
          title: "Tapón marco",
          category: "Accesorios para cercas",
          image: "26.jpg",
          medidas: "TAPON MARCO.jpg",
          nuevo: false,
        },
        {
          id: 8,
          title: "Tapón púa",
          category: "Accesorios para cercas",
          image: "27.jpg",
          medidas: "TAPON DE PUA.jpg",
          nuevo: false,
        }
      ],
    }
  },
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

html {
  font-size: 16px;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
ul,
li {
  font-family: "Roboto", "Arial", "Calibri";
}

body {
  font-weight: 300;
  background-color: #ffffff !important;
}

main {
  min-height: 90vh;
}

.navbar .btn,
.navbar .nav-item,
.navbar .nav-link {
  padding: 0;
}

.select-wrapper input.select-dropdown {
  font-size: 14px;
}

.form-control {
  font-size: 14px;
}

.fullscreen {
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100000;
  min-height: 100vh;
}

.fullscreen-view {
  height: 100vh;
}

.list-group .ml-auto {
  float: right;
  flex: none !important;
}

.navbar-brand {
  cursor: pointer;
}


.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: all 1.1s ease-in-out;
}

.fade-enter,
.fade-leave {
  opacity: 0;
}

</style>
