import Vue from 'vue'
import Router from 'vue-router'

import Main from '../views/index.vue'
const Product = () => import('../views/product.vue')
const AllProducts = () => import('../views/allProducts.vue')
const Follage = () => import('../views/follaje.vue')
const Madera = () => import('../views/madera.vue')
const Servicios = () => import('../views/services.vue')
const Clientes = () => import('../views/clients.vue')

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Cercas y mallas',
      component: Main
    },
    {
      path: '/producto/:id',
      name: 'Product',
      component: Product
    },
    {
      path: '/productos/',
      name: 'productos',
      component: AllProducts
    },
    {
      path: '/productos/:category',
      name: 'category',
      component: AllProducts
    },
    {
      path: '/follaje',
      name: 'Follage artificial',
      component: Follage
    },
    {
      path: '/maderaplas',
      name: 'Maderaplas',
      component: Madera
    },
    {
      path: '/servicios',
      name: 'Servicios',
      component: Servicios
    },
    {
      path: '/servicios/:city',
      name: 'Ciudad',
      component: Servicios
    },
    {
      path: '/clientes',
      name: 'Ciudad',
      component: Clientes
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      savedPosition.behavior = 'smooth'
      return savedPosition
    } else {
      if (to.name == 'productos') {
        return { x: 0, y:  600, behavior: 'smooth',}
      }
      if (to.name == 'category') {
        return { x: 0, y:  600, behavior: 'smooth',}
      }
      return { x: 0, y: 0 , behavior: 'smooth',}
    }
  }
})
