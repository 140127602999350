<template>
<div class="container">
  <link href="https://fonts.googleapis.com/css?family=Work+Sans:400,600,700&display=swap" rel="stylesheet">
<div class="layout">
  <input name="nav" type="radio" class="nav home-radio" id="home" />
  <div class="page home-page">
    <div class="page-contents">
      <div class="container">
        <div class="row">
              <div class="col-md-4 mb-4">
                <router-link to="/follaje"><hcard image="follaje/2.jpg" text="jardin vertical" color="green-1"/></router-link>
              </div>
              <div class="col-md-4 mb-4">
                <router-link to="/follaje"><hcard image="follaje/1.jpg" text="muro verde" color="green-1"/></router-link>
              </div>
              <div class="col-md-4 mb-4">
                <router-link to="/follaje"><hcard image="follaje/6.jpg" text="jardin sintetico" color="green-1"/></router-link>
              </div>
            </div>
      </div>
    </div>
  </div>
  <label class="nav" for="home">
    <span>
      Follage artificial
    </span>
  </label>

  <input name="nav" type="radio" class="about-radio" id="about" checked="checked"/>
  <div class="page about-page">
    <div class="page-contents">
      <div class="row">
              <div class="col-md-4 mb-4">
                <label for="home"><hcard image="follaje/1.jpg" text="Follaje" color="green-1"/></label>
              </div>
              <div class="col-md-4 mb-4">
                <a href="https://sistemasdeseguridadyproteccion.com/"><hcard image="seguridad/2.jpg" text="Seguridad" color="blue"/></a>
              </div>
              <div class="col-md-4 mb-4">
                <label for="contact"><hcard image="madera2.jpg" text="Maderaplas" color="madera"/></label>
              </div>
            </div>
    </div>
  </div>
  <label class="nav" for="about">
    <span class="d-block d-sm-block d-md-none">
    Seguridad
      </span>
    <span class="d-none d-sm-none d-md-block">
    Sistemas de seguridad
      </span>
    </label>

  <input name="nav" type="radio" class="contact-radio" id="contact" />
  <div class="page contact-page">
    <div class="page-contents">
      <div class="row">
              <div class="col-md-4 mb-4">
                <router-link to="/maderaplas"><hcard image="madera2.jpg" text="terrazas" color="madera"/></router-link>
              </div>
              <div class="col-md-4 mb-4">
                <router-link to="/maderaplas"><hcard image="madera1.jpg" text="pergolas" color="madera"/></router-link>
              </div>
              <div class="col-md-4 mb-4">
                <router-link to="/maderaplas"><hcard image="madera3.jpg" text="Maderaplas" color="madera"/></router-link>
              </div>
            </div>
    </div>
  </div>
  <label class="nav" for="contact">
    <span>
    Maderaplas
      </span>
    
  </label>
</div>
</div>
</template>

<script>
import Hcard from './hoverableCard'

export default {
components: {
  Hcard,
}
}
</script>

<style scoped lang="scss">
*{
  font-family: 'Work Sans', sans-serif;
}
html, body{
  height: 100%;
  color: #1a1b1c;
}

label, a{
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  display: inline-block;
  color: inherit;
  transition: border 0.2s;
  border-bottom: 5px solid rgba(#ed213a, 0.2);
  padding: 3px 2px;
  &:hover{
    border-bottom-color:#ed213a;
  }
}

.layout{
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

input[type="radio"]{
  display: none;
}

label.nav{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 2px solid  #aa1706;
  background: #ecf0f1;
  user-select: none;
  transition: background 0.4s, padding-left 0.2s;
  padding-left: 0;
  input[type="radio"]:checked + .page + &{
    background: #ed213a;
    color: #ffffff;
    svg{
      opacity: 1;
    }
  }
  span{
    padding-left: 0px;
    position: relative;
  }
  svg{
    left: 0;
    top: -3px;
    position: absolute;
    width: 15px;
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.page{
  grid-column-start: 1;
  grid-row-start: 2;
  grid-column-end: span 3;
  padding: 0px 20px;
  display: flex;
  align-items: center;
}

.page-contents > *{
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s, transform 0.2s;
  @for $c from 1 through 20{
    &:nth-child(#{$c}){
      transition-delay: 0.2s + ($c * 0.2s);
    }
  }
}

input[type="radio"] + .page{
  transition: transform 0.2s;
  transform: translateX(100%);
}

input[type="radio"]:checked + .page{
  transform: translateX(0%);
  .page-contents > *{
    opacity: 1;
    transform: translateY(0px);
  }
}

.page-contents{
  max-width: 100%;
  margin: 0 auto;
}
</style>