<template>
  <figure class="photo" :class="color">
  <img :src="require(`../assets/${image}`)"/>
  <figcaption>
    <h2><span>{{text}}</span></h2>
  </figcaption>
</figure>
</template>

<script>

export default {
  components: {
    //mdbBtn,
  },
  props: {
    image: String,
    color: String,
    text: String
  },
};
</script>

<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
figure.photo {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  max-height: 220px;
  width: 100%;
  background: #000000;
  color: #ffffff;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

figure.photo * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

figure.photo img {
  max-width: 100%;
  position: relative;
  opacity: 0.4;
}

figure.photo figcaption {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

figure.photo h2 {
  position: absolute;
  left: 40px;
  right: 40px;
  display: inline-block;
  background: #000000;
  -webkit-transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
  transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
  padding: 12px 5px;
  margin: 0;
  top: 50%;
  text-transform: uppercase;
  font-weight: 400;
}

figure.photo h2 span {
  font-weight: 800;
}

figure.photo:before {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: '';
  background: rgba(177, 177, 177, 0.678);
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(110deg) translateY(-50%);
  transform: rotate(110deg) translateY(-50%);
}

figure.photo a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

figure.photo.blue {
  background: rgb(18, 56, 81, 0.973);
}

figure.photo.blue h2 {
  background: #0a212f;
}

figure.photo.red {
  background: rgb(88, 26, 20, 0.973);
}

figure.photo.red h2 {
  background: #be2716;
}

figure.photo.green-1 {
  background: rgba(21, 128, 7, 0.973);
}

figure.photo.green-1 h2 {
  background: #2bd625;
}

figure.photo.madera h2 {
  background: rgba(212, 55, 7, 0.973);
}

figure.photo:hover img,
figure.photo.hover img {
  opacity: 1;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.photo:hover h2,
figure.photo.hover h2 {
  -webkit-transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
  transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
}

figure.photo:hover:before,
figure.photo.hover:before {
  -webkit-transform: rotate(110deg) translateY(-150%);
  transform: rotate(110deg) translateY(-150%);
}
</style>