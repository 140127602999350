<template>
  <transition name="fade">
  <div class="page-loader" v-if="!isloaded">
      <transition name="up">
      <div v-if="!pre">
        <div class="m1 d-none d-sm-none d-md-block"></div>
        <div class="m3 d-block d-sm-block d-md-none"></div>
      </div>
    </transition>
      <transition name="down">
      <div v-if="!pre">
        <div class="m2 d-none d-sm-none d-md-block"></div>
        <div class="m4 d-block d-sm-block d-md-none"></div>
      </div>
    </transition>
  </div>
  </transition>
</template>

<script>
export default {
  data: () => {
    return {
      isloaded: false,
      pre: false
    };
  },
  mounted() {
    this.cargar();
  },
  methods: {
    cargar() {
      setTimeout(() => {
        this.isloaded = true;
      }, 5000);

      setTimeout(() => {
        this.pre = true
      }, 4200)
    },
  },
};
</script>

<style lang="scss" scoped>
.page-loader {
  display: block;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 9999;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.m1 {
  background: url('../assets/Desktop-up.jpg') no-repeat center bottom;
  background-size: cover;
  background-color: #ffffff;
  height: 50vh;
}
.m2 {
  background: url('../assets/Desktop-down.jpg') no-repeat top;
  background-size: cover;
  background-color: #ffffff;
  height: 50vh;
}
.m3 {
  background: url('../assets/Movile-up.jpg') no-repeat center bottom;
  background-size: cover;
  background-color: #ffffff;
  height: 50vh;
}
.m4 {
  background: url('../assets/Movile-down.jpg') no-repeat top;
  background-size: cover;
  background-color: #ffffff;
  height: 50vh;
}
.up-enter-active {
  animation: moveUp 0.5s reverse;
}
.up-leave-active {
  animation: moveUp 0.8s;
}
.down-enter-active {
  animation: moveDown 0.5s reverse;
}
.down-leave-active {
  animation: moveDown 0.8s;
}
@keyframes moveUp {
  0% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(-100vh);
  }
}

@keyframes moveDown {
  0% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(100vh);
  }
}
</style>