<template>
  <div>
   <div class="pt-4 mt-4">
      <agile
      class="d-none d-sm-none d-md-block"
        autoplay="autoplay"
        autoplaySpeed="4000"
        fade="fade"
        :pause-on-hover="false"
        :dots="false"
        :nav-buttons="false"
      >
        <div class="slide car-1">
          <div class="text-center mx-5">
            <h1 class="car-title">Grupo Versátil</h1>
            <h3 class="car-sub pt-5">
              Cercas y Mallas, Reja Forte, Púas, Concertina, Maderaplas, Follaje
              artificial y más...
            </h3>
          </div>
        </div>
        <div class="slide car-2">
          <div class="text-center mx-5">
            <h1 class="car-title">
              Con Presencia en el 80% de la República Mexicana
            </h1>
            <h3 class="car-sub2 pt-5">
              Comunícate por Nuestros Medios de Contacto
            </h3>
          </div>
        </div>
        <div class="slide car-3">
          <div class="text-center mx-5">
            <h1 class="car-title">Grupo Versátil</h1>
          <h3 class="car-sub2 pt-5">
              Cercas y Mallas, Reja Forte, Púas, Concertina, Maderaplas, Follaje
              artificial y más...
            </h3>
          </div>
        </div>
      </agile>
      <agile
      class="d-block d-sm-block d-md-none"
        autoplay="autoplay"
        autoplaySpeed="4000"
        fade="fade"
        :pause-on-hover="false"
        :dots="false"
        :nav-buttons="false"
      >
        <div class="slide car-1-mobile">
          <div class="text-center mx-5">
            <h1 class="car-title-mobile">Grupo Versátil</h1>
            <h3 class="car-sub-mobile pt-5">
              Cercas y Mallas, Reja Forte, Púas, Concertina, Maderaplas, Follaje
              artificial y más...
            </h3>
          </div>
        </div>
        <div class="slide car-2-mobile">
          <div class="text-center mx-5">
            <h1 class="car-title-mobile">
              Con Presencia en el 80% de la República Mexicana
            </h1>
            <h3 class="car-sub2-mobile pt-1">
              Comunícate por Nuestros Medios de Contacto
            </h3>
          </div>
        </div>
        <div class="slide car-3-mobile">
          <div class="text-center mx-5">
            <h1 class="car-title-mobile">Grupo Versátil</h1>
          <h3 class="car-sub2-mobile pt-5">
              Cercas y Mallas, Reja Forte, Púas, Concertina, Maderaplas, Follaje
              artificial y más...
            </h3>
          </div>
        </div>
      </agile>
    </div>
    <div>
      <div class="row my-red mx-0 text-center">
        <div class="container mt-3 text-center">
          <mdb-dropdown class="d-block d-sm-block d-md-none">
            <mdb-dropdown-toggle slot="toggle" color="danger" class="white-text" size="md"
              >Otros servicios</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu color="danger" end>
              <mdb-dropdown-item tag="a" class="red-text" href="https://www.desarrollointegralcanino.com/" target="_blank"
                >Desarrollo integral canino</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="a" class="red-text" href="https://cercasymallas.com/" target="_blank"
                >Cercas y malllas</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="a" class="red-text" href="https://www.cercasdemalla.com.mx/" target="_blank"
                >Cercas de malla</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="a" class="red-text" href="https://www.cercasymallasversatiles.com/" target="_blank"
                >Cercas y malllas versatiles</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="a" class="red-text" href="https://cercasymallasintegrales.com.mx/" target="_blank"
                >Cercas y malllas integrales</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="a" class="red-text" href="https://www.sistemasdeseguridadyproteccion.com/" target="_blank"
                >Sistemas de seguridad</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>
          <div class="d-none d-sm-none d-md-block">
            |
          <a
            target="_blank"
            href="https://www.desarrollointegralcanino.com/"
            class="mx-3 subt"
            ><small>Desarrollo integral canino </small></a
          >|
          <a
            target="_blank"
            href="https://cercasymallas.com/"
            class="mx-3 subt"
          >
            <small>Cercas y malllas </small></a
          >|
          <a
            target="_blank"
            href="https://www.cercasdemalla.com.mx/"
            class="mx-3 subt"
          >
            <small>Cercas de malla</small></a
          >|
          <a
            target="_blank"
            href="https://www.cercasymallasversatiles.com/"
            class="mx-3 subt"
          >
            <small>Cercas y malllas versatiles </small></a
          >|
          <a
            target="_blank"
            href="https://cercasymallasintegrales.com.mx/"
            class="mx-3 subt"
          >
            <small>Cercas y malllas integrales</small></a
          >|
          <a
            target="_blank"
            href="https://www.sistemasdeseguridadyproteccion.com/"
            class="mx-3 subt"
          >
            <small>Sistemas de seguridad</small></a
          >|
          </div>
        </div>
        <div class="container mt-1 mb-3 text-center amber-text">
          <strong class="mr-1">Sucursales:</strong>
          <mdb-dropdown class="mt-1">
            <mdb-dropdown-toggle slot="toggle" class="white-text" size="sm"
              >Edo. México</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu color="danger">
              <mdb-dropdown-item tag="router-link" to="/servicios/Coacalco"
                >Coacalco</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Nezahualcóyotl"
                >Nezahualcóyotl</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Naucalpan"
                >Naucalpan</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Tlalnepantla"
                >Tlalnepantla</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Chimalhuacán"
                >Chimalhuacán</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Cuautitlán"
                >Cuautitlán</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Ecatepec"
                >Ecatepec</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Atizapán"
                >Atizapán</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>
          <mdb-dropdown class="mt-1">
            <mdb-dropdown-toggle slot="toggle" class="white-text" size="sm"
              >Edo. Guerrero</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu color="danger">
              <mdb-dropdown-item tag="router-link" to="/servicios/Acapulco"
                >Acapulco</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Chilpancingo"
                >Chilpancingo</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Iguala"
                >Iguala</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Zihuatanejo"
                >Zihuatanejo</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Tlapa"
                >Tlapa</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Taxco"
                >Taxco</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Chilapa"
                >Chilapa</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Zumpango"
                >Zumpango</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>
          <mdb-dropdown class="mt-1">
            <mdb-dropdown-toggle slot="toggle" class="white-text" size="sm"
              >Edo. Puebla</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu color="danger">
              <mdb-dropdown-item tag="router-link" to="/servicios/Puebla"
                >Puebla</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Tehuacán"
                >Tehuacán</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Teziutlán"
                >Teziutlán</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/San Pedro Cholula"
                >San Pedro Cholula</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Atlixco"
                >Atlixco</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Amozoc"
                >Amozoc</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/San Martín Texmelucan"
                >San Martín Texmelucan</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Huauchinango"
                >Huauchinango</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>
          <mdb-dropdown class="mt-1">
            <mdb-dropdown-toggle slot="toggle" class="white-text" size="sm"
              >Edo. Veracruz</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu color="danger">
              <mdb-dropdown-item tag="router-link" to="/servicios/Alvarado"
                >Alvarado</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Boca del Rio"
                >Boca del Rio</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Catemaco"
                >Catemaco</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Coatzacoalcos"
                >Coatzacoalcos</mdb-dropdown-item
              >
               <mdb-dropdown-item tag="router-link" to="/servicios/Cordoba"
                >Cordoba</mdb-dropdown-item
              >
               <mdb-dropdown-item tag="router-link" to="/servicios/Huatusco"
                >Huatusco</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Minatitlan"
                >Minatitlan</mdb-dropdown-item
              >
               <mdb-dropdown-item tag="router-link" to="/servicios/Orizaba"
                >Orizaba</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Papantla"
                >Papantla</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Perote"
                >Perote</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Poza Rica"
                >Poza Rica</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Tuxpan"
                >Tuxpan</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Veracruz puerto"
                >Veracruz puerto</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Xalapa"
                >Xalapa</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>
          <mdb-dropdown class="mt-1">
            <mdb-dropdown-toggle slot="toggle" class="white-text" size="sm"
              >Resto de la republica</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu color="danger">
              <mdb-dropdown-item tag="router-link" to="/servicios/Querétaro"
                >Querétaro</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Morelos"
                >Morelos</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Cuernavaca"
                >Cuernavaca</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Tulancingo"
                >Tulancingo</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Hidalgo"
                >Hidalgo</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Pachuca"
                >Pachuca</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Tlaxcala"
                >Tlaxcala</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Oaxaca"
                >Oaxaca</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Chiapas"
                >Chiapas</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Tabasco"
                >Tabasco</mdb-dropdown-item
              >
              <mdb-dropdown-item tag="router-link" to="/servicios/Tampico"
                >Tampico</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </div>
      </div>
    </div>
    <div class="container mt-3">
      <div class="row d-flex align-items-center">
        <div class="col-md-4 mt-4">
          <mdb-card class="rounded amber lighten-1">
            <mdb-card-body>
              <router-link to="/producto/21">
                <mdb-card
                  class="my-card-2 my-card-mini-mallaciclonica mb-2 hoverable"
                >
                  <mdb-card-body class="text-center my-pt-2">
                    <h5><strong>Malla Ciclonica</strong></h5>
                  </mdb-card-body>
                </mdb-card>
              </router-link>
              <router-link to="/producto/28">
                <mdb-card class="my-card-2 my-card-mini-reja mb-2 hoverable">
                  <mdb-card-body class="text-center my-pt-2">
                    <h5><strong>Rejas de acero</strong></h5>
                  </mdb-card-body>
                </mdb-card>
              </router-link>
              <router-link to="/producto/40">
                <mdb-card class="my-card-2 my-card-mini-cercas mb-2 hoverable">
                  <mdb-card-body class="text-center my-pt-2">
                    <h5><strong>Cercas electricas</strong></h5>
                  </mdb-card-body>
                </mdb-card>
              </router-link>
              <router-link to="/producto/13">
                <mdb-card
                  class="my-card-2 my-card-mini-concertina mb-2 hoverable"
                >
                  <mdb-card-body class="text-center my-pt-2">
                    <h5><strong>Concertina</strong></h5>
                  </mdb-card-body>
                </mdb-card>
              </router-link>
              <a
                target="_blank"
                href="https://sistemasdeseguridadyproteccion.com/"
              >
                <mdb-card class="my-card-2 my-card-mini-alarmas mb-2 hoverable">
                  <mdb-card-body class="text-center my-pt-2">
                    <h5><strong>Sistemas de seguridad</strong></h5>
                  </mdb-card-body>
                </mdb-card>
              </a>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div class="col-md-8 mt-4">
          <div class="d-flex justify-content-center py-3">
            <video autoPlay controls style="width: 70%">
              <source src="../assets/main2.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="conatiner calidad z-depth-1">
            <div class="white-text py-4 ml-3 pl-3">
              <div class="d-none d-sm-none d-md-block">
                <span class="badge badge-danger mt-4"
                  >La calidad nos Distinge</span
                >
                <h2 class="card-title font-weight-bold pt-2">
                  <strong>En Cercas y Mallas Versátiles</strong>
                </h2>
                <p class="">
                  Nos dedicamos a la Instalación, Mantenimiento y Reparación de
                  todo tipo de Cercas de Malla ciclónica, PVC o Galvanizada.
                  Utilizamos exclusivamente materiales de la más alta calidad
                  para garantizar su Cercado.
                </p>
              </div>
              <div class="d-block d-sm-block d-md-none text-center">
                <span class="badge badge-danger mt-4"
                  >La calidad nos Distinge</span
                >
                <h2 class="card-title font-weight-bold pt-2">
                  <strong>Cercas y Mallas Versátiles</strong>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-5 pt-3 pb-5">
      <div class="row">
        <div class="col-md-4 mx-auto">
          <router-link to="/productos/Sombra y Mosquitera">
            <mdb-card class="my-card-2 my-card-mallamosquitera mb-2 hoverable">
              <mdb-card-body class="text-center my-pt-2">
                <h5><strong>Malla mosquitera y Malla sombra</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/producto/36">
            <mdb-card class="my-card-cintaplastica my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center my-pt">
                <h5><strong>Cinta plastica</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/productos/Mallas para Animales">
            <mdb-card class="my-card-mallaborreguera my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4">
                <h5 class="my-0 py-0"><strong>Malla borreguera</strong></h5>
                <h5 class="my-0 py-0"><strong>Multimalla</strong></h5>
                <h5 class="my-0 py-0"><strong>Criba</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/productos/Alambre">
            <mdb-card class="my-card-alambre my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center my-pt">
                <h5><strong>Alambres</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/productos/Otras Mallas">
            <mdb-card class="my-card-metal my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4">
                <h5 class="pb-0 mb-0"><strong>Triple torsión</strong></h5>
                <h5 class="my-0 py-0"><strong>Hexagonal</strong></h5>
                  <h5 class="mt-0 pt-0"><strong>Gaviones</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/productos/Tubo Galvanizado">
            <mdb-card class="my-card-tubo my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center my-pt-2">
                <h5><strong>Tubo galvanizado y PTR galvanizado</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/productos/Metal y Laminas">
            <mdb-card class="my-card-laminas my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4">
                <h5 class="my-0 py-0"><strong>Metal deplegado</strong></h5>
                <h5 class="my-0 py-0"><strong>Lamina perforada</strong></h5>
                <h5 class="my-0 py-0"><strong>Láminas</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/productos/Tuberia">
            <mdb-card class="my-card-tuberia my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center my-pt">
                <h5><strong>Tuberia para cercas</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/productos/Accesorios para cercas">
            <mdb-card
              class="my-card-accesorios my-card-2 my-card-accesorios mb-2 hoverable"
            >
              <mdb-card-body class="text-center my-pt">
                <h5><strong>Accesorios para cercas</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/follaje">
            <mdb-card class="my-card-follaje my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center my-pt">
                <h5><strong>Follaje</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
        <div class="col-md-4 mx-auto">
          <a target="_blank" href="https://www.desarrollointegralcanino.com/">
            <mdb-card
              class="my-card-seguridadcanofila my-card-2 mb-2 hoverable"
            >
              <mdb-card-body class="text-center my-pt">
                <h5><strong>Seguridad canofila</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </a>
        </div>
        <div class="col-md-4 mx-auto">
          <router-link to="/maderaplas">
            <mdb-card class="my-card-madera my-card-2 mb-2 hoverable">
              <mdb-card-body class="text-center my-pt">
                <h5><strong>Maderaplas</strong></h5>
              </mdb-card-body>
            </mdb-card>
          </router-link>
        </div>
      </div>
    </div>
    <a
      href="https://sistemasdeseguridadyproteccion.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <section class="container mt-4" data-aos="zoom-in">
        <div class="view z-depth-1 gradient-orange rounded">
          <img
            src="../assets/camaras.png"
            class="img-fluid"
            alt="sample image"
            width="35%"
          />
          <div class="mask">
            <div class="text-right pt-lg-5 pt-md-1 mr-5 pr-md-4 pr-0">
              <span class="badge elegant-color-dark mt-3">Nuevo</span>
              <div class="my-zoom">
                <h3
                  class="card-title text-white font-weight-bold pt-md-3 mt-5 d-none d-sm-none d-md-block"
                >
                  <strong
                    >Contamos con sistemas de seguridad y vigilancia
                  </strong>
                </h3>
                <h4
                  class="card-title text-white font-weight-bold pt-md-3 pt-1 d-block d-sm-block d-md-none"
                >
                  <strong>Sistemas de</strong><br />
                  <strong>Seguridad</strong>
                </h4>
                <p
                  class="pb-lg-3 pb-md-1 text-white clearfix d-none d-md-block"
                >
                  Control de acceso y ciruito cerrado
                </p>
              </div>
              <a
                target="_blank"
                href="https://sistemasdeseguridadyproteccion.com"
                class="d-none d-sm-none d-md-block"
                ><mdb-btn
                  outline="primai"
                  rounded
                  class="text-white elegant-color-dark mt-1"
                  >Ver más</mdb-btn
                ></a
              >
            </div>
          </div>
        </div>
      </section></a
    >
    <section class="container mt-5">
      <div class="row">
        <div class="col-md mx-1">
          <hr />
          <a href="https://cercasymallas.com/">
            <h5 class="text-center font-weight-bold dark-grey-text">
              <strong>Cercas y Mallas</strong>
            </h5>
          </a>
          <hr />
          <div v-for="(item, i) in newProducts" :key="i" class="mt-4">
            <mini-card
              :id="item.id"
              :title="item.title"
              :price="item.price"
              :sale="item.sale"
              :image="item.image"
              :categoria="item.category"
              data-aos="zoom-in-up"
            />
          </div>
        </div>
        <div class="col-md mx-1">
          <hr />
          <a href="https://sistemasdeseguridadyproteccion.com/">
            <h5 class="text-center font-weight-bold dark-grey-text">
              <strong>Sistemas de seguridad</strong>
            </h5>
          </a>
          <hr />
          <div class="mt-4" v-for="(item, i) in topSellers" :key="i">
            <mini-card
              :id="item.id"
              :title="item.title"
              :price="item.price"
              :nuevo="item.nuevo"
              :sale="item.sale"
              :image="item.image"
              :categoria="item.category"
              data-aos="zoom-in-up"
            />
          </div>
        </div>
        <div class="col-md mx-1">
          <hr />
          <a href="https://cercasymallasversatiles.com.mx/">
            <h5 class="text-center font-weight-bold dark-grey-text">
              <strong>Mallas Versátiles</strong>
            </h5>
          </a>
          <hr />
          <div class="mt-4" v-for="(item, i) in randomProducts" :key="i">
            <mini-card
              :id="item.id"
              :title="item.title"
              :price="item.price"
              :nuevo="item.nuevo"
              :sale="item.sale"
              :image="item.image"
              :categoria="item.category"
              data-aos="zoom-in-up"
            />
          </div>
        </div>
      </div>
    </section>
    <hr class="mt-5 pb-2" />
    <div class="mt-5 pb-5">
      <div class="text-center mb-5">
        <h2 class="font-weight-bold">Más de nuestros servicios</h2>
      </div>
      <tabs />
    </div>

    <a target="_blank" href="https://api.whatsapp.com/message/OM43BU5OG5GNE1"
      ><mdb-btn
        color="succes"
        class="gradient-red-2 text-white"
        rounded
        id="fixedbutton"
        ><strong
          ><p class="mb-0">Solicitar</p>
          Cotización</strong
        ></mdb-btn
      ></a
    >
  </div>
</template>

<script>
import MiniCard from "../components/MiniCard.vue";
import Tabs from "../components/tabs.vue";
import {
  mdbBtn,
  mdbCard,
  mdbCardBody,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
} from "mdbvue";
import { VueAgile } from "vue-agile";

export default {
  data() {
    return {
      newProducts: [this.items[0], this.items[3], this.items[8]],
      randomProducts: [this.items[20], this.items[12], this.items[2]],
      topSellers: [this.items[4], this.items[1], this.items[9]],
    };
  },
  props: {
    items: Array,
  },
  components: {
    MiniCard,
    mdbBtn,
    Tabs,
    mdbCard,
    mdbCardBody,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    agile: VueAgile,
  },
  mounted() {
    document.title = "Cercas y Mallas Versatiles";
  },
};
</script>

<style>
.gradient-red-2 {
  background: #43ed21; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #26a107,
    #43ed21
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #26a107,
    #43ed21
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.my-red {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#fixedbutton {
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin-right: 30px;
  margin-bottom: 25px;
  z-index: 999;
}
.gradient-orange {
  background: #fe8c00; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f83600,
    #fe8c00
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f83600,
    #fe8c00
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.my-card-accesorios {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/accesorios.jpg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-alambre {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/alambre.jpg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-concertina {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/concertina.png") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-laminas {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/7.jpeg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-mallaciclonica {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/cercas2.jpeg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-mallamosquitera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/3.jpeg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-mallaborreguera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/5.jpeg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-cintaplastica {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallaciclonicaconcintaplastica.jpg") center
      no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-seguridadcanofila {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/seguridadcanina.jpg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-metal {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/1.jpeg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-reja {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/rejadeacero.jpg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-tubo {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/8.jpeg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-tuberia {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/2.jpeg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-follaje {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/follaje/follaje/10.jpg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-madera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/madera/madera005.jpg") center no-repeat;
  background-size: cover;
  height: 120px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-mini-mallaciclonica {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/cercas2.jpeg") center no-repeat;
  background-size: cover;
  height: 95px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-mini-reja {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/rejadeacero.jpg") center no-repeat;
  background-size: cover;
  height: 95px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-mini-concertina {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/concertina.png") center no-repeat;
  background-size: cover;
  height: 95px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-mini-alarmas {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/circuito.jpg") center no-repeat;
  background-size: cover;
  height: 95px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-mini-cercas {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/cercaselectricas.jpg") center no-repeat;
  background-size: cover;
  height: 95px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.my-card-2 h5 {
  font-size: 1.5rem;
}
.my-card-2:hover,
.my-card-2:focus,
.my-card-2:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.subt {
  color: #fff;
  text-decoration: none;
  position: relative;
}
.subt:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 1;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.subt:hover:after {
  width: 100%;
  left: 0;
}
.subt:hover {
  color: black;
  transition: all 0.4s ease 0s;
}
.my-zoom:hover {
  transform: scale(1.1);
  transition: all 0.8s ease 0s;
}
.my-pt {
  margin-top: 30px;
}
.my-pt-2 {
  margin-top: 15px;
}
.calidad {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/main2.jpg") center no-repeat;
  background-size: cover;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.car-1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/1.jpg") center no-repeat;
  background-size: cover;
  height: 510px;
}
.car-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/2.jpg") center no-repeat;
  background-size: cover;
  height: 510px;
}
.car-3 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/3.jpg") center no-repeat;
  background-size: cover;
  height: 510px;
}
.car-1-mobile {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/1m.jpg") center no-repeat;
  background-size: cover;
  height: 350px;
}
.car-2-mobile {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/2m.jpg") center no-repeat;
  background-size: cover;
  height: 350px;
}
.car-3-mobile {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets//carousel/3m.jpg") center no-repeat;
  background-size: cover;
  height: 350px;
}
.car-title {
  color: rgb(196, 7, 7);
  -webkit-text-stroke: 3px white;
  font-size: 4.5rem;
  font-weight: bold;
  font-style: italic;
}
.car-sub {
  color: white;
  -webkit-text-stroke: 2px rgb(13, 13, 112);
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
}
.car-sub2 {
  color: rgb(218, 179, 9);
  -webkit-text-stroke: 2px rgb(255, 255, 255);
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
}
.car-title-mobile {
  color: rgb(196, 7, 7);
  -webkit-text-stroke: 2px white;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
}
.car-sub-mobile  {
  color: white;
  -webkit-text-stroke: 1px rgb(13, 13, 112);
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
}
.car-sub2-mobile  {
  color: rgb(218, 179, 9);
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
}
</style>