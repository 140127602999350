<template>
  <div class="row py-2 mb-3 hoverable align-items-center rounded elegant-color-dark">
    <div class="col-6">
        <router-link :to="`/producto/${id}`"><img
          :src="require(`../assets/products/${image}`)"
          class="img-fluid rounded"
          style="max-height: 8rem"
        /></router-link>
    </div>
    <div class="col-6">
      <!-- Title -->
        <div class="white-text">
          <h5><strong>{{ title }}</strong></h5>
        </div>

      <!-- Price -->
        <div class="container text-center my-2">
          <a target="_blank" :href="`https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20${title}%20`"><mdb-btn color="green" class="mx-auto white-text" size="sm">Cotizar<mdb-icon fab icon="whatsapp" class="ml-2"/></mdb-btn></a>
          <router-link :to="`/producto/${id}`"><mdb-btn color="orange ancent-4" class="mx-auto px-3 white-text" size="sm">Ficha tecnica</mdb-btn></router-link>
        </div>
    </div>
  </div>
</template>

<script>
  import { mdbBtn, mdbIcon } from 'mdbvue';

export default {
  props: {
    id: Number,
    title: String,
    price: String,
    nuevo: Boolean,
    sale: Boolean,
    bestseller: Boolean,
    image: String,
    categoria: String,
  },
  components: {
      mdbBtn,
      mdbIcon
  }
};
</script>

<style>
</style>