<template>
  <footer class="page-footer font-small blue-grey lighten-5">
    <div
      style="
        background: #ed213a;
        background: -webkit-linear-gradient(to right, #93291e, #ed213a);
        background: linear-gradient(to right, #93291e, #ed213a);
      "
    >
      <div class="container">
        <!-- Grid row-->
        <div class="row py-4 d-flex align-items-center">
          <!-- Grid column -->
          <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
            <h6 class="mb-0">
              Contactate con nosotros en nuestras redes sociales!
            </h6>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-6 col-lg-7 text-center text-md-right">
            <!-- Facebook -->
            <a
              target="_blank"
              class="fb-ic"
              href="https://www.facebook.com/cercasymallasversatil"
            >
              <i class="fab fa-facebook-f white-text mr-4"> </i>
            </a>
            <!-- Twitter -->
            <a
              target="_blank"
              class="tw-ic"
              href="https://twitter.com/CercasVersa"
            >
              <i class="fab fa-twitter white-text mr-4"> </i>
            </a>
            <!--Instagram-->
            <a
              target="_blank"
              class="ins-ic"
              href="https://www.instagram.com/cercasymallasversatiles/"
            >
              <i class="fab fa-instagram white-text mr-4"> </i>
            </a>
            <a
              target="_blank"
              class="ins-ic"
              href="https://www.youtube.com/channel/UCw6xUXlqTvWvgMQN9nWUDJQ"
            >
              <i class="fab fa-youtube white-text"> </i>
            </a>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row-->
      </div>
    </div>

    <!-- Footer Links -->
    <div class="container text-center text-md-left mt-5">
      <!-- Grid row -->
      <div class="row mt-3 dark-grey-text">
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mb-4">
          <!-- Content -->
          <h6 class="text-uppercase font-weight-bold">Cercas y Mallas</h6>
          <hr
            class="red accent-3 mb-4 mt-0 d-inline-block mx-auto"
            style="width: 60px"
          />
          <p>
            Una empresa en seguridad integral desde mallas perimetrales, cercas
            electrónicas, ciclónicas, puertas automáticas, hasta circuito
            cerrado de vídeo y perros entrenados, contemplando la visión
            estética también en la instalación de toldos y forraje artificial
            para poderte ofrecer el servicio completo para tu negocio o empresa,
            pide tu presupuesto.
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Servicios</h6>
          <hr
            class="red accent-3 mb-4 mt-0 d-inline-block mx-auto"
            style="width: 60px"
          />
          <p>
            <a
              target="_blank"
              class="dark-grey-text"
              href="https://www.cercasdemalla.com.mx/"
              >Cercas de Malla</a
            >
          </p>
          <p>
            <a
              target="_blank"
              class="dark-grey-text"
              href="https://sistemasdeseguridadyproteccion.com/"
              >Seguridad y Vigilancia</a
            >
          </p>
          <p>
            <a
              target="_blank"
              class="dark-grey-text"
              href="https://www.cercasymallasversatiles.com/"
              >Cercas y mallas versatiles</a
            >
          </p>
          <p>
            <a
              target="_blank"
              class="dark-grey-text"
              href="https://www.desarrollointegralcanino.com/"
              >Perros Guardianes</a
            >
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Contacto</h6>
          <hr
            class="red accent-3 mb-4 mt-0 d-inline-block mx-auto"
            style="width: 60px"
          />
          <p>
            <i class="fas fa-home mr-3"></i> Xalapa, Puebla, Tlaxcala, Acapulco
            Cordoba, Orizaba, Oaxaca, Hidalgo, Chilpancingo, Villa Hermosa,
            Ciudad de méxico, Guerrero, Centro sur y Suroeste de México
          </p>
          <p><i class="fas fa-envelope mr-3"></i>grupoversatil72@gmail.com</p>
          <p><i class="fas fa-envelope mr-3"></i>versatil8@hotmail.com</p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Llamanos</h6>
          <hr
            class="red accent-3 mb-4 mt-0 d-inline-block mx-auto"
            style="width: 60px"
          />
          <p><strong>Para toda la republica:</strong></p>
          <p><i class="fas fa-phone mr-3"></i> 01 800 727 9437</p>
          <p><i class="fas fa-phone mr-3"></i> 744 254 2295</p>
          <p><i class="fas fa-mobile-alt mr-3"></i>55 4321 0444</p>
          <p><i class="fab fa-whatsapp mr-3"></i> 229 306 0014</p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
    <!-- Footer Links -->

    <!-- Copyright -->
    <div class="footer-copyright text-center text-white red darken-4 pt-3">
      <p class="mb-0 pb-0">© 2020 Copyright: Cercas y Mallas</p>
      <small
        ><p>
          <a target="_blank" href="https://ayudate.online" class="mb-0 pb-0"
            >Diseñado por: Ayudate Online ®</a
          >
        </p></small
      >
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
