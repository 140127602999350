<template>
<mdb-navbar class="line" expand="large" position="top"  color="red darken-4" dark>
    <mdb-navbar-brand href="#">
      <router-link to="/"><img src="../assets/logo2.png" height="30" alt=""></router-link>
    </mdb-navbar-brand>
    <mdb-navbar-toggler> 
      <mdb-navbar-nav>
        <mdb-nav-item class="d-none d-sm-none d-md-block">
          <mdb-btn tag="a" href="https://twitter.com/cercasversa" style="border-radius: 50%;"  class="btn-tw px-1 py-1" icon="twitter" fab />
          <mdb-btn tag="a" href="https://www.facebook.com/cercasymallasversatil/" class="px-1 py-1 btn-fb" icon="facebook-f" fab style="border-radius: 50%;" />
          <mdb-btn tag="a" href="https://www.youtube.com/channel/UCw6xUXlqTvWvgMQN9nWUDJQ" style="border-radius: 50%;" class="px-1 py-1 btn-yt" icon="youtube" fab></mdb-btn>
          <mdb-btn tag="a" href="https://www.instagram.com/cercasymallasversatiles/" style="border-radius: 50%;" class="px-1 py-1 btn-ins" icon="instagram" fab></mdb-btn>
          <mdb-btn tag="a" href="https://api.whatsapp.com/send?phone=52744%20254%202295" color="success" size="sm" rounded icon="whatsapp" fab>+52 744 254 2295</mdb-btn>
        </mdb-nav-item>
      </mdb-navbar-nav>
      <mdb-navbar-nav  right>
        <router-link to="/"><mdb-nav-item class="font-weight-bold mr-2 my-2"><mdb-icon icon="home" class="white-text mr-1"/>Inicio</mdb-nav-item></router-link>
        <router-link to="/servicios"><mdb-nav-item class="font-weight-bold mr-2 my-2"><mdb-icon icon="toolbox" class="grey-text mr-1"/>Servicios</mdb-nav-item></router-link>
        <router-link to="/productos"><mdb-nav-item class="font-weight-bold mr-2 my-2"><mdb-icon icon="store" class="amber-text mr-1"/>Productos</mdb-nav-item></router-link>
        <router-link to="/follaje"><mdb-nav-item class="font-weight-bold my-2"><mdb-icon fab icon="pagelines" class="green-text mr-1"/>Follage artificial</mdb-nav-item></router-link>
        <router-link to="/maderaplas"><mdb-nav-item class="font-weight-bold my-2"><mdb-icon icon="stream" class="orange-text mr-1"/>MaderaPlas</mdb-nav-item></router-link>
        <router-link to="/clientes"><mdb-nav-item class="font-weight-bold my-2"><mdb-icon icon="clipboard-list" class="white-text mr-1"/>Clientes</mdb-nav-item></router-link>
        <mdb-nav-item class="d-block d-sm-block d-md-none">
          <mdb-btn tag="a" href="https://twitter.com/cercasversa" style="border-radius: 50%;"  class="btn-tw px-1 py-1" icon="twitter" fab />
          <mdb-btn tag="a" href="https://www.facebook.com/cercasymallasversatil/" class="px-1 py-1 btn-fb" icon="facebook-f" fab style="border-radius: 50%;" />
          <mdb-btn tag="a" href="https://www.youtube.com/channel/UCw6xUXlqTvWvgMQN9nWUDJQ" style="border-radius: 50%;" class="px-1 py-1 btn-yt" icon="youtube" fab></mdb-btn>
          <mdb-btn tag="a" href="https://www.instagram.com/cercasymallasversatiles/" style="border-radius: 50%;" class="px-1 py-1 btn-ins" icon="instagram" fab></mdb-btn>
          <mdb-btn tag="a" href="https://api.whatsapp.com/send?phone=52744%20254%202295" color="success" size="sm" rounded icon="whatsapp" fab>+52 744 254 2295</mdb-btn>
        </mdb-nav-item>
      </mdb-navbar-nav>
    </mdb-navbar-toggler>
  </mdb-navbar>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbIcon,
  mdbBtn

} from "mdbvue";

export default {
  name: "Navbar",
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbIcon,
    mdbBtn,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.line {
  border-bottom: 3px solid #ffffff;
}
</style>
